import React, { useState, useEffect, useRef } from 'react';
import { Table, Container, Card, Button, Col } from 'react-bootstrap';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import MD5 from 'crypto-js/md5';

import authHeader from '../../auth-header';

import ConfirmRequest from '../../components/confirm';
import PrintHeader from '../../images/PrintHeaderResize.jpg';
import Logo from '../../images/ExcelLogo.png';

//import EditPending from './editPending';

const ShowOrder = (props) => {
  let navigate = useNavigate();
  let params = useParams();

  const [order, setOrder] = useState([]);
  const [parts, setParts] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [shipFrom, setShipFrom] = useState([]);
  const [orderType, setOrderType] = useState(0);
  const [notFound, setNotFound] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [processModalShow, setProcessModalShow] = useState(false);
  const [pendingModalShow, setPendingModalShow] = useState(false);
  const [unpickModalShow, setUnpickModalShow] = useState(false);

  const tableRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setParts([]);
    // get the order type
    const type = searchParams.get('type') || orderType;
    // console.log('showOrder type ', type, props.customer);
    if (type) {
      // console.log("order type = " + type);
      setOrderType(parseInt(type));
      getOrder(type);
    } else {
      axios
        .get('/api/orders/getType?orderNum=' + params.orderId, {
          headers: authHeader(),
        })
        .then(function (response) {
          // console.log(response.data);
          setOrderType(response.data.orderType);
          getOrder(response.data.orderType);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [params.orderId]);

  const getOrder = (orderType) => {
    axios
      .get(`/api/orders/lookup?type=${orderType}&orderNum=${params.orderId}`, {
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log(response.data);
        setNotFound(false);
        setOrder(response.data.info);
        setParts(response.data.parts);
        setShipping(response.data.shipping);
        setShipFrom(response.data.shipFrom);
      })
      .catch(function (error) {
        //  error, assume not found
        setNotFound(true);
        console.log(error);
      });
  };

  const editOrderHandler = () => {
    setEditModalShow(false);

    navigate(`/editOrder/${params.orderId}`);
  };

  const processOrderHandler = () => {
    setProcessModalShow(false);
    axios
      .post(
        '/api/orders/processPending',
        {
          orderNum: params.orderId,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        navigate(`/order/${params.orderId}?type=2`);
        setOrderType(2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const pickOrderHandler = () => {
    navigate(`/pickOrder/${params.orderId}`);
  };

  const deleteInProcessHandler = () => {
    setPendingModalShow(false);
    axios
      .post(
        '/api/orders/deleteInProcess',
        {
          orderNum: params.orderId,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        navigate(`/order/${params.orderId}?type=1`);
        setOrderType(1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const unpickOrderHandler = () => {
    setUnpickModalShow(false);
    axios
      .post(
        '/api/orders/unpick',
        {
          orderNum: params.orderId,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
        navigate(`/order/${params.orderId}?type=1`);
        setOrderType(1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const exportDataHandler = () => {
    // remove id field
    const result = parts.map(({ id, ...rest }) => ({ ...rest }));
    const ws = XLSX.utils.json_to_sheet(result);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, `Report_Outbound-${params.orderId}.xlsx`);

    // const table_elt = tableRef.current;

    // const workbook = XLSX.utils.table_to_book(table_elt, {
    //   dateNF: "yyyy-MM-DD hh:mm:ss",
    // });

    // XLSX.writeFile(workbook, `Report_Outbound-${params.orderId}.xlsx`);
  };

  function addHeader(doc) {
    const pageWidth = doc.internal.pageSize.getWidth();
    const imgWidth = 112;
    const imgHeight = 50;
  
    doc.addImage(Logo, 'JPEG', (pageWidth - imgWidth) / 2, 20, imgWidth, imgHeight, '', 'NONE');
  
    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
  
    const text = 'Material Management';
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize();
  
    doc.text(text, (pageWidth - textWidth) / 2, 90);
    doc.setFont(undefined, 'normal');
  }
  

  const generatePDF = () => {
    let doc = new jsPDF('l', 'pt');
    const pageWidth = doc.internal.pageSize.getWidth();

    const user = JSON.parse(localStorage.getItem('user'));
    const now = new Date();

    //doc.setFont("helvetica");
    doc.setFontSize(10);
    doc.setLineWidth(1);

    doc.text(650, 20, '-' + MD5(user.realname + now).toString() + '-');

    //doc.addImage(PrintHeader, 200, 20, 450, 87, '', 'NONE');
    addHeader(doc);

    const rectWidth = 700;
    const xPosition = (pageWidth - rectWidth) / 2;

    // doc.text(60, 135, 'Site:');
    // doc.text(370, 135, 'Ordered By:');
    // doc.text(600, 135, 'Date Ordered:');
    // doc.rect(45, 120, rectWidth, 50, 'S');

    // doc.text(60, 195, 'Date Pickup:');
    // doc.text(370, 195, 'Ordered For:');
    // doc.text(600, 195, 'Order #:');

    // doc.rect(45, 180, rectWidth, 50, 'S');
    // doc.setFont(undefined, 'bold');
    // doc.text(60, 155, order.SiteName);
    // doc.text(370, 155, order.OrderedBy);
    // doc.text(600, 155, order.DateOrdered);
    // doc.text(60, 215, order.DatePickUp);
    // doc.text(370, 215, order.OrderedFor);
    // doc.text(600, 215, params.orderId);
    // doc.setFont(undefined, 'normal');

    doc.text(xPosition + 15, 125, 'Site:');
    doc.text(xPosition + 325, 125, 'Ordered By:');
    doc.text(xPosition + 540, 125, 'Date Ordered:');
    doc.rect(xPosition, 110, rectWidth, 50, 'S');

    doc.text(xPosition + 15, 185, 'Date Pickup:');
    doc.text(xPosition + 325, 185, 'Ordered For:');
    doc.text(xPosition + 540, 185, 'Order #:');
    doc.rect(xPosition, 170, rectWidth, 50, 'S');

    doc.setFont(undefined, 'bold');
    doc.text(xPosition + 15, 145, order.SiteName);
    doc.text(xPosition + 325, 145, order.OrderedBy);
    doc.text(xPosition + 540, 145, order.DateOrdered);

    doc.text(xPosition + 15, 205, order.DatePickUp);
    doc.text(xPosition + 325, 205, order.OrderedFor);
    doc.text(xPosition + 540, 205, params.orderId);
    doc.setFont(undefined, 'normal');


    let finalY = 230;
    // notes
    if (order.notes?.length > 0) {
      let rows = [];
      // rows.push({ notes: order.notes });
      doc.autoTable({
        columns: [{ header: 'Notes', dataKey: 'Notes' }],
        // body: rows,
        body: order.notes,
        startY: 245,
        tableLineColor: 0,
        theme: 'plain',
        margin: 20,
        styles: {
          font: 'helvetica',
          lineWidth: 1,
          lineColor: 0,
          cellPadding: 3,
        },
        headStyles: { fillColor: null, textColor: 0 },
        bodyStyles: { textColor: 0 },
      });
      finalY = doc.autoTable.previous.finalY;
    }

    // console.log(finalY);

    // parts
    doc.autoTable({
      columns: [
        { header: 'PartNum', dataKey: 'PartNum' },
        { header: 'ALUPONum', dataKey: 'ALUPONum' },
        { header: 'PONum', dataKey: 'PONum' },
        { header: 'SONum', dataKey: 'SONum' },
        { header: 'Warehouse', dataKey: 'Warehouse' },
        { header: 'Description', dataKey: 'Description' },
        { header: 'Quantity', dataKey: 'Quantity' },
      ],
      body: parts,
      startY: finalY + 15,
      theme: 'striped',
      tableLineColor: 0,
      margin: 20,
      styles: { font: 'helvetica', lineWidth: 1, lineColor: 0, cellPadding: 3 },
      headStyles: { fillColor: null, textColor: 0 },
      bodyStyles: { textColor: 0 },
    });
    finalY = doc.autoTable.previous.finalY;

    // shipping

    if (shipping) {
      let rows = [];
      rows.push(shipping);
      doc.autoTable({
        columns: [
          { header: 'CompanyName', dataKey: 'CompanyName' },
          { header: 'ContactName', dataKey: 'ContactName' },
          { header: 'ContactNumber', dataKey: 'ContactNumber' },
          { header: 'Street', dataKey: 'Street' },
          { header: 'Street2', dataKey: 'Street2' },
          { header: 'City', dataKey: 'City' },
          { header: 'Province', dataKey: 'Province' },
          { header: 'PostalCode', dataKey: 'PostalCode' },
        ],
        body: rows,
        startY: finalY + 15,
        tableLineColor: 0,
        theme: 'grid',
        margin: 20,
        styles: {
          font: 'helvetica',
          lineWidth: 1,
          lineColor: 0,
          cellPadding: 3,
        },
        headStyles: { fillColor: null, textColor: 0 },
        bodyStyles: { textColor: 0 },
      });
      finalY = doc.autoTable.previous.finalY;
    }

    const y = finalY + 20;

    doc.text(30, y, 'Received By:');
    doc.line(95, y, 280, y);
    doc.text(300, y, 'Date & Time:');
    doc.line(365, y, 550, y);
    doc.text(570, y, 'Contact #:');
    doc.line(625, y, 810, y);

    doc.save(`PickList-${params.orderId}.pdf`);
  };

  const totalQuantity = () => {
    return parts.reduce((sum, cur) => sum + parseInt(cur.Quantity), 0);
  };

  const Summary = () => (
    <Table responsive>
      <thead>
        <tr>
          <th>Site</th>
          <th>Date Ordered</th>
          <th>Pickup Date</th>
          <th>Requested By</th>
          <th>Requested For</th>
          {typeof order.PickedBy !== 'undefined' && <th>Picked By</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{order.SiteName} </td>
          <td>{order.DateOrdered} </td>
          <td>{order.DatePickUp} </td>
          <td>{order.OrderedBy} </td>
          <td>{order.OrderedFor} </td>
          {typeof order.PickedBy !== 'undefined' && <td>{order.PickedBy} </td>}
        </tr>
      </tbody>
    </Table>
  );

  const Notes = () => {
    return (
      <>
        {order.notes?.length > 0 && (
          <Card border="primary" className="mb-3">
            <Card.Body>
              {order.notes.map((item, index) => (
                <Card.Text key={index}>{item.Notes}</Card.Text>
              ))}
              {/* <Card.Text>{order.notes[0].Notes}</Card.Text> */}
            </Card.Body>
          </Card>
        )}
      </>
    );
  };

  const Shipping = () => (
    // <Container fluid>
    <Table responsive>
      <thead>
        <tr>
          <th>Ship To</th>
          <th>Contact Number</th>
          <th>Address</th>
          <th>Address 2</th>
          <th>City</th>
          <th>Province</th>
          <th>Postal Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{shipping.CompanyName} </td>
          <td>{shipping.ContactName} </td>
          <td>{shipping.ContactNumber} </td>
          <td>{shipping.Street} </td>
          <td>{shipping.Street2} </td>
          <td>{shipping.City} </td>
          <td>{shipping.Province} </td>
          <td>{shipping.PostalCode} </td>
        </tr>
      </tbody>
    </Table>
    // </Container>
  );

  const ShipFrom = () => (
    // <Container fluid>
    <Table responsive>
      <thead>
        <tr>
          <th>Ship From</th>
          <th>Contact Number</th>
          <th>Address</th>
          <th>Address 2</th>
          <th>City</th>
          <th>Province</th>
          <th>Postal Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{shipFrom.CompanyName} </td>
          <td>{shipFrom.ContactName} </td>
          <td>{shipFrom.ContactNumber} </td>
          <td>{shipFrom.Street} </td>
          <td>{shipFrom.Street2} </td>
          <td>{shipFrom.City} </td>
          <td>{shipFrom.Province} </td>
          <td>{shipFrom.PostalCode} </td>
        </tr>
      </tbody>
    </Table>
    // </Container>
  );

  const Detail = () => (
    <Table ref={tableRef}>
      <thead>
        <tr>
          <th>Owner</th>
          <th>Part Number</th>
          <th>{props.customer === '34' ? 'ALU PO #' : 'Vendor'}</th>
          <th>Customer PO#</th>
          <th>SO #</th>
          <th>Warehouse</th>
          {orderType === 3 && (
            <>
              <th>Serial Number</th>
              <th>SKU Number</th>
            </>
          )}
          <th>Description</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part, index) => (
          <tr key={part.id}>
            <td>{part.Owner} </td>
            <td>{part.PartNum} </td>
            <td>{part.ALUPONum} </td>
            <td>{part.PONum} </td>
            <td>{part.SONum} </td>
            <td>{part.Warehouse} </td>
            {orderType === 3 && (
              <>
                <td>{part.SerialNum} </td>
                <td>{part.SKUNum} </td>
              </>
            )}
            <td>{part.Description} </td>
            <td>{part.Quantity} </td>
            {/* <td style={{textAlign: "right"}}>{part.Quantity} </td> */}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="6" />
          {orderType === 3 && <td colSpan={2} />}
          <th>Total</th>
          <th>{totalQuantity()}</th>
        </tr>
      </tfoot>
    </Table>
  );

  const ShowOrder = () => (
    <Container fluid>
      <ConfirmRequest
        message="Are you sure you want to edit this order?"
        showModal={editModalShow}
        onConfirm={editOrderHandler}
        onHide={() => setEditModalShow(false)}
      />
      <ConfirmRequest
        message="Are you sure you want to place this order in process?"
        showModal={processModalShow}
        onConfirm={processOrderHandler}
        onHide={() => setProcessModalShow(false)}
      />
      <ConfirmRequest
        message="Are you sure you want to move this order back to pending?"
        showModal={pendingModalShow}
        onConfirm={deleteInProcessHandler}
        onHide={() => setPendingModalShow(false)}
      />
      <ConfirmRequest
        message="Are you sure you want to unpick this order?"
        showModal={unpickModalShow}
        onConfirm={unpickOrderHandler}
        onHide={() => setUnpickModalShow(false)}
      />
      <h4 style={{ backgroundColor: '#F0F0F0' }}>
        {orderType === 1 && 'Pending Order # '}
        {orderType === 2 && 'Order in Process # '}
        {orderType === 3 && 'Completed Order # '}
        {params.orderId}
      </h4>
      <Summary />
      <Notes />
      <Detail />
      {typeof shipFrom !== 'undefined' && <ShipFrom />}
      {typeof shipping !== 'undefined' && <Shipping />}
      {orderType === 1 && (
        <Col className="mb-3">
          <Button
            // variant="outline-primary"
            className="me-3"
            // onClick={() => setProcessModalShow(true)}
            onClick={() => processOrderHandler()}
          >
            Process Order
          </Button>
          <Button
            variant="outline-secondary"
            className="me-3"
            // onClick={() => setEditModalShow(true)}
            onClick={() => editOrderHandler()}
          >
            Edit Order
          </Button>
          <Button variant="outline-secondary" onClick={() => generatePDF()}>
            Save PickList (.pdf)
          </Button>
        </Col>
      )}
      {orderType === 2 && (
        <Col className="mb-3">
          <Button
            // variant="outline-primary"
            className="me-3"
            onClick={() => pickOrderHandler()}
          >
            Pick Order
          </Button>
          <Button variant="outline-secondary" className="me-3" onClick={() => setPendingModalShow(true)}>
            Move to Pending
          </Button>
          <Button variant="outline-secondary" onClick={() => generatePDF()}>
            Save PickList (.pdf)
          </Button>
        </Col>
      )}
      {orderType === 3 && (
        <Col className="mb-3">
          <Button
            // variant="outline-primary"
            className="me-5"
            onClick={() => setUnpickModalShow(true)}
          >
            Unpick Order
          </Button>
          <Button variant="outline-primary" onClick={exportDataHandler}>
            Save Data (.xlsx)
          </Button>
        </Col>
      )}
    </Container>
  );

  return (
    <>
      {notFound && <h3 style={{ textAlign: 'center' }}>Not found</h3>}
      {parts.length > 0 && <ShowOrder />}
    </>
  );
};

export default ShowOrder;
